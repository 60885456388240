import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ProjectCardsContent from "./ProjectCardsContent";
import arrowRight from "../Images/arrow-right.png";
import { GlobalContext } from "../Consts/GlobalContext";

const ProjectCardsWrapper = styled.div`
  padding: 0 150px 115px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .read-more-btn {
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    button {
      width: auto;
      height: auto;
      background-color: transparent;
      width: auto;
      img {
        padding-left: 10px;
      }
    }
  }
  @media (max-width: 426px) {
    padding: 0 53px 40px;
  }
`;

const ProjectCards = () => {
  const [showMore, setShowMore] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectsLoad, setProjectsLoad] = useState([]);
  const [projectsAll, setProjectsAll] = useState([]);
  const [projectsFiltered, setProjectsFiltered] = useState([]);
  const [end, setEnd] = useState(4);
  const { filters, setFilters } = useContext(GlobalContext);

  useEffect(() => {
    fetch(`${window.baseurl}/api/projects`)
      .then((response) => response.json())
      .then((data) => {
        setProjectsLoad(data);
        setProjectsAll(data);
        console.log(data);
        console.log(filters);
        if (window.innerWidth < 426) {
          setProjects(data.slice(0, end));
          setShowMore(true);
        } else {
          setProjects(data);
          setShowMore(false);
        }
      });
  }, []);

  useEffect(() => {
    setShowMore(true);
    if (projectsFiltered.length) {
      setProjects(projectsFiltered.slice(0, end));
      if (projects.length + 4 >= projectsFiltered.length) {
        setShowMore(false);
      }
    } else {
      setProjects(projectsLoad.slice(0, end));
      if (end === projectsLoad.length) {
        setShowMore(false);
      }
    }
  }, [end]);

  useEffect(() => {
    let temp = [];
    let filtered = [];
    if (window.innerWidth < 426) {
      if (
        projects.length === projectsFiltered.length ||
        projects.length === projectsLoad.length
      ) {
        console.log('raboti')
        setShowMore(false);
      } else {
        setShowMore(true);
      }
      if (filters == "All") {
        setShowMore(true);
        setProjects(projectsLoad.slice(0, end));
        setProjectsFiltered([]);
        if (end === projectsLoad.length) {
          setShowMore(false);
        }
      } else {
        filtered = projectsLoad.filter((el) => {
          temp = el.services.find((el) => el.title === filters);
          return temp;
        });
        setProjects(filtered.slice(0, end));
      }
      setProjectsFiltered(filtered);
    } else {
      if (filters == "All") {
        setProjects(projectsLoad);
      } else {
        filtered = projectsLoad.filter((el) => {
          temp = el.services.find((el) => el.title === filters);
          return temp;
        });
        setProjects(filtered);
      }
      setShowMore(false);
    }
  }, [filters]);

  const loadMore = () => {
    setEnd((prevValue) => prevValue + 4);
  };

  return (
    <ProjectCardsWrapper>
      {projects &&
        projects.map((project) => (
          <ProjectCardsContent
            key={project.id}
            id={project.id}
            name={project.title}
            location={project.location}
            description={project.description}
            img={project.images}
          />
        ))}
      <div className="read-more-btn">
        {showMore && (
          <button className="buttonReadMore" onClick={loadMore}>
            See more projects <img src={arrowRight} alt="" />
          </button>
        )}
      </div>
    </ProjectCardsWrapper>
  );
};

export default ProjectCards;
