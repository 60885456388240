import React from "react";
import Partner1Img from "../Images/partner1.png"
import Partner2Img from "../Images/partner2.png"
import Partner3Img from "../Images/partner3.png"
import styled from "styled-components";

const PartnersWrapper = styled.div`
color: ${(props) => props.theme.text_blue};
  padding: 186px 225px 120px;

  h3{
      text-align: center;
  }
  div{
      display:flex;
      justify-content: space-between;
      align-items: center;
  }
  @media (max-width:426px){
    padding: 122px 0 160px;
    h3{
      font-size: 36px !important;
      line-height: 45px;
    }
    div{
      img:first-of-type{
        width:130px;
        height :55px;
      }
      img:nth-of-type(2){
        width:63px;
        height :76px;
      }
      img:last-of-type{
        width:94px;
      }
    }
  }
`;
const Partners = () => {
  return (
    <PartnersWrapper>
      <h3>Our partners</h3>
      <div>
        <img src={Partner1Img} alt="" />
        <img src={Partner2Img} alt="" />
        <img src={Partner3Img} alt="" />
      </div>
    </PartnersWrapper>
  );
};

export default Partners;
