import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GlobalContext } from "../Consts/GlobalContext";

const ProjectCardsContentWrapper = styled.div`
  min-height: 250px;
  width: 22%;
  position: relative;
  margin: 15px;
  div {
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .inner {
    overflow: hidden;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    .title,
    .location {
      font-family: "Teko", "sans-serif";
      font-size: 36px;
      line-height: 43px;
    }
    .title {
      font-weight: 500;
      letter-spacing: 2px;
    }
    .location {
      font-weight: 300;
      letter-spacing: 4px;
    }
  }
  &:hover .inner {
    display: flex;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
`;

const ProjectCardsContent = ({ id, name, location, img }) => {
  const { randomImage } = useContext(GlobalContext);
  return (
    <ProjectCardsContentWrapper>
      <Link to={`/projects/details/${id}`}>
        <div>
          <img
            src={`${
              window.baseurl + "/" + img[randomImage(img.length)].images
            }`}
            alt=""
          />
        </div>
        <div className="inner">
          <p className="title">{name}</p>
          <p className="location">{location}</p>
        </div>
      </Link>
    </ProjectCardsContentWrapper>
  );
};

export default ProjectCardsContent;
