import React from "react";
import styled from "styled-components";
import Image1 from "../Images/joint-calculations1.png";
import Image2 from "../Images/joint-calculations2.png";
import Image3 from "../Images/joint-calculations3.png";
import Image4 from "../Images/joint-calculations4.png";
import Image5 from "../Images/joint-calculations5.png";
import Image6 from "../Images/joint-calculations6.png";
import Image7 from "../Images/joint-calculations7.png";
import Image8 from "../Images/joint-calculations8.png";
const JointImagesWrapper = styled.div`
  padding: 0 150px 120px;
  div {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    > div {
      min-width:23%;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
      img{
        margin:0 auto;
      }
    }
  }

  @media (max-width: 426px) {
    padding: 0 0 0 16px;
    > div {
      overflow-x: scroll;
      flex-wrap: nowrap;
      margin-bottom: 60px;
      margin-right: 0px;
      >div{
        min-width:auto;
      }
    }
  }
`;
const JointImages = (props) => {
  return (
    <JointImagesWrapper>
      <div>
        <div>
          <img src={Image1} alt="" />
        </div>
        <div>
          <img src={Image2} alt="" />
        </div>
        <div>
          <img src={Image3} alt="" />
        </div>
        <div>
          <img src={Image4} alt="" />
        </div>
        <div>
          <img src={Image5} alt="" />
        </div>
        <div>
          <img src={Image6} alt="" />
        </div>
        <div>
          <img src={Image7} alt="" />
        </div>
        <div>
          <img src={Image8} alt="" />
        </div>
      </div>
    </JointImagesWrapper>
  );
};

export default JointImages;
