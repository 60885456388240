import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const ProjectSliderCardsWrapper = styled.div`
  a {
    display: block;
    width: 100%;
    position: relative;
    .card-details {
      display: none;
      position: absolute;
      text-align: center;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.2);
      div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      h2 {
        color: white;
      }
      p {
        color: white;
        ${(props) => props.theme.body_2}
      }
    }
  }
  a:hover .card-details {
    display: block;
  }
`;
const ProjectSliderCards = ({ id, title, location, image }) => {
  return (
    <ProjectSliderCardsWrapper>
      <Link to={`/projects/details/${id}`}>
        <img src={`${window.baseurl}/${image.images}`} alt="" />
        <div className="card-details">
          <div>
            <h2>{title}</h2>
            <p>{location}</p>
          </div>
        </div>
      </Link>
    </ProjectSliderCardsWrapper>
  );
};

export default ProjectSliderCards;
