import React from "react";
import styled from "styled-components";
import Team1 from "../Images/team1.png";
import Team2 from "../Images/team2.png";
import Team3 from "../Images/team3.png";
import Avatar from "../Images/avatar.png";

const AboutTeamWrapper = styled.div`
  padding: 145px 150px 70px;
  color: ${(props) => props.theme.text_blue};
  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid ${(props) => props.theme.border_color};
    margin-top: 70px;
  }
  h2 {
    text-transform: uppercase;
  }
  .about-team-container {
    display: flex;
    align-items: stretched;
    margin-right: -15px;
    margin-left: -15px;
    .about-team-card {
      width: 33%;
      margin-right: 15px;
      margin-left: 15px;
      .about-team-card-inner {
        position: relative;
      }

      img {
        width: 100%;
        display: block;
      }

      .inner {
        overflow: hidden;
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: rgba(34, 52, 74, 0.7);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-align: center;
      }
      .inner-content {
        font-family: "Teko", "sans-serif";
        font-size: 36px;
        line-height: 43px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .about-team-card-inner:hover .inner {
        display: flex;
        cursor: pointer;
      }

      .name {
        ${(props) => props.theme.caption_1};
        margin-top: 20px;
      }

      .position {
        ${(props) => props.theme.caption_1};
        font-weight: 400;
      }
    }
  }

  @media (max-width: 426px) {
    padding: 60px 16px 41px;
    h2{
      font-size:48px !important;
      line-height:56px;
    }
    .about-team-container {
      margin-top:35px;
      flex-direction: column;
      .about-team-card{
        width:auto;
        .name,
        .position{
          font-size:20px !important;
          line-height:34px;
        }
        .position{
          font-weight:400;
        }
      }
      .about-team-card:not(:last-of-type) {
        margin-bottom:50px;
      }
    }
  }
`;
const AboutTeam = () => {
  return (
    <AboutTeamWrapper>
      <h2>Meet our team</h2>
      <div className="about-team-container">
        <div className="about-team-card">
          <div className="about-team-card-inner">
            <div>
              <img src={Team1} alt="" />
            </div>
            <div className="inner">
              <p className="inner-content">read more</p>
            </div>
          </div>
          <div>
            <p className="name">Viktor Angelovski</p>
            <p className="position">Structural Engineer</p>
          </div>
        </div>

        <div className="about-team-card">
          <div className="about-team-card-inner">
            <div>
              <img src={Team2} alt="" />
            </div>
            <div className="inner">
              <p className="inner-content">read more</p>
            </div>
          </div>
          <div>
            <p className="name">Marko Mitic</p>
            <p className="position">Structural Engineer</p>
          </div>
        </div>

        <div className="about-team-card">
          <div className="about-team-card-inner">
            <div>
              <img src={Team3} alt="" />
            </div>
            <div className="inner">
              <p className="inner-content">read more</p>
            </div>
          </div>
          <div>
            <p className="name">Marija Smitkova</p>
            <p className="position">Structural Engineer</p>
          </div>
        </div>

        <div className="about-team-card">
          <div className="about-team-card-inner">
            <div>
              <img src={Avatar} alt="" />
            </div>
            <div className="inner">
              <p className="inner-content">Join our team</p>
            </div>
          </div>
          <div>
            <p className="name">Work with us ?</p>
            <p className="position">This could be you!</p>
          </div>
        </div>
      </div>
      <hr />
    </AboutTeamWrapper>
  );
};

export default AboutTeam;
