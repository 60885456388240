import React, { useContext, useState } from "react";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import { FormControl, InputLabel, TextField } from "@material-ui/core";
import PhoneIcon from "../Images/phone.png";
import { Form } from "react-bootstrap";
import axios from "axios";
import { GlobalContext } from "../Consts/GlobalContext";
import successTick from "../Images/success-tick.png";
import uploadImg from "../Images/upload.png"

const ContactFormWrapper = styled.div`
  color: ${(props) => props.theme.text_blue};
  padding: 175px 257px 75px;
  h3,
  h4 {
    text-align: center;
  }
  h4 {
    margin-bottom: 90px;
    font-weight: 300;
  }
  .phone {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form {
    color: ${(props) => props.theme.text_blue};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  form .group {
    width: 50%;
    margin-bottom: 50px;
    .file-project {
      width: 300px;
      margin-left: auto;
    }
  }
  form .message {
    width: 100%;
  }
  form .message .input {
    width: 100%;
    label {
      ${(props) => props.theme.caption_2};
    }
    #standard-basic {
      padding-bottom: 17px;
      font-size: 24px;
    }
  }
  form .group .input {
    width: 300px;
    label {
      ${(props) => props.theme.caption_2};
    }
    #standard-basic {
      padding-bottom: 17px;
      font-size: 24px;
    }
    select {
      ${(props) => props.theme.subtitle_1};
      height: auto;
      option {
        margin-bottom: 10px;
      }
    }
  }
  form .group:nth-child(even) {
    display: flex;
    justify-content: flex-end;
  }

  form .location {
    select {
      ${(props) => props.theme.subtitle_2};
      font-weight: 400;
    }
  }
  form .submit-button {
    width: 100%;
    margin-top: 100px;
    text-align: right;
    ${(props) => props.theme.caption_1};
    button {
      padding: 15px 40px;
      background-color: ${(props) => props.theme.button_yellow};
    }
  }
  .MuiSelect-select:focus {
    background: transparent;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, -2.5px) scale(0.75);
  }

  .error {
    color: #eb5757;
    ${(props) => props.theme.button_txt2};
    font-size: 14px !important;
  }

  input[type="file"] {
    display: none;
  }
  .custom-input-file {
    position: relative;
    margin-top: 15px;
    label {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      ${(props) => props.theme.caption_2};
      display: flex;
      padding: 6px 0 17px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      justify-content:space-between;
      span:last-of-type{
        position:relative;
        z-index:1000;
        img{
          width:100%;
          display:block;
        }
      }
    }
    label.file-selected span:first-of-type {
      display: block;
      position: relative;
      top: -25px;
      left: 0;
      transform: translateX(-21px) scale(0.7);
    }
    p {
      position: absolute;
      bottom: 17px;
      font-size: 24px;
      height:1.1876em;
      overflow: hidden;
    }
  }
  @media (max-width: 426px) {
    padding: 95px 16px 65px;
    h3 {
      font-size: 48px !important;
      line-height: 56px;
      margin-bottom: 35px;
    }
    h4 {
      font-size: 36px !important;
      line-height: 45px;
      margin-bottom: 45px;
    }
    form {
      flex-direction: column;
      .group {
        width: 100%;
        .input {
          width: 100%;
        }
        .file-project {
          margin-left: 0;
          width: 100%;
        }
      }
    }
    form .group:nth-child(even) .input {
      margin-left: 0;
    }
  }
  .input-text.catch-err .MuiFormLabel-root.Mui-focused,
  .input-text.catch-err .MuiFormLabel-root {
    color: ${(props) => props.theme.error_color};
  }
  .input-text.catch-err .MuiInput-underline:before,
  .input-text.catch-err .MuiInput-underline:after {
    border-color: ${(props) => props.theme.error_color};
  }

  .success-message {
    display: flex;
    position: fixed;
    opacity: 0;
    top: -30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;
    background-color: white;
    color: #007e33;
    z-index: 100000;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 8px 10px 35px #dadde0;
    transition: all 2s ease-in-out;
    img {
      display: block;
      width: 100px;
    }
  }
  .success-message.show {
    top: 50%;
    opacity: 1;
  }

  @media (min-width: 1441px) {
    .form {
      width: 70%;
      margin: 0 auto;
    }
  }
`;

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [schemeValue, setSchemeValue] = useState("");
  const [schemeFile, setSchemeFile] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [success, setSuccess] = useState(false);
  const { project, setProject } = useContext(GlobalContext);

  const submit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("company", company);
    formData.append("email", email);
    formData.append("location", location);
    formData.append("message", message);
    formData.append("phone", phone);
    formData.append("scheme", schemeFile);

    axios
      .post(`${window.baseurl}/api/inquiry/add`, formData, {})
      .then((res) => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
        setFirstName("");
        setLastName("");
        setCompany("");
        setEmail("");
        setLocation("");
        setMessage("");
        setPhone("");
        setSchemeValue("");
        setFirstNameErr("");
        setLastNameErr("");
        setCompanyErr("");
        setEmailErr("");
        setMessageErr("");
        setPhoneErr("");
      })
      .catch((error) => {
        let errors = Object.values(error)[2].data.errors;

        if (errors) {
          setFirstNameErr(errors.first_name ? errors.first_name[0] : "");
          setLastNameErr(errors.last_name ? errors.last_name[0] : "");
          setCompanyErr(errors.company ? errors.company[0] : "");
          setEmailErr(errors.email ? errors.email[0] : "");
          setMessageErr(errors.message ? errors.message[0] : "");
          setPhoneErr(errors.phone ? errors.phone[0] : "");
        }
      });
  };

  const handleProjectChange = (e) => {
    if (e.target.value === "project") {
      setProject(true);
    } else {
      setProject(false);
      setSchemeValue("");
      setLocation("");
    }
  };

  const handleFileUpload = (e) => {
    setSchemeValue(e.target.value);
    setSchemeFile(e.target.files[0]);
  };

  return (
    <ContactFormWrapper>
      <div className={success ? "success-message show" : "success-message"}>
        <img src={successTick} alt="" />
        The Form has been successfully sent.
      </div>
      <h3>Let's talk!</h3>
      <h4>
        We would love to share ideas, suggest options and answer your questions.
      </h4>
      <h4 className="phone">
        <img src={PhoneIcon} alt="" />
        +43 2638 1929
      </h4>
      <form
        className="form"
        onSubmit={(e) => submit(e)}
        encType="multipart/form-data"
      >
        <div className="group">
          <FormControl className="input">
            <InputLabel htmlFor="grouped-native-select">
              Inquiry type
            </InputLabel>
            <Select
              native
              defaultValue=""
              id="grouped-native-select"
              value={project ? "project" : "general"}
              onChange={(e) => handleProjectChange(e)}
            >
              <option value="project">Project</option>
              <option value="general">General</option>
            </Select>
          </FormControl>
        </div>
        <div className="group">
          <FormControl
            className={
              companyErr !== ""
                ? "input input-text catch-err"
                : "input input-text"
            }
          >
            <TextField
              id="standard-basic"
              label="Company Name"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            {companyErr !== "" && <div className="error">{companyErr}</div>}
          </FormControl>
        </div>
        <div className="group">
          <FormControl
            className={
              firstNameErr !== ""
                ? "input input-text catch-err"
                : "input input-text"
            }
          >
            <TextField
              id="standard-basic"
              label="First Name"
              name="first_name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstNameErr !== "" && <div className="error">{firstNameErr}</div>}
          </FormControl>
        </div>
        <div className="group">
          <FormControl
            className={
              lastNameErr !== ""
                ? "input input-text catch-err"
                : "input input-text"
            }
          >
            <TextField
              id="standard-basic"
              label="Last Name"
              name="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastNameErr !== "" && <div className="error">{lastNameErr}</div>}
          </FormControl>
        </div>
        <div className="group">
          <FormControl
            className={
              emailErr !== ""
                ? "input input-text catch-err"
                : "input input-text"
            }
          >
            <TextField
              id="standard-basic"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailErr !== "" && <div className="error">{emailErr}</div>}
          </FormControl>
        </div>
        <div className="group">
          <FormControl
            className={
              phoneErr !== ""
                ? "input input-text catch-err"
                : "input input-text"
            }
          >
            <TextField
              id="standard-basic"
              label="Phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            {phoneErr !== "" && <div className="error">{phoneErr}</div>}
          </FormControl>
        </div>
        {project && (
          <div className="group">
            <FormControl className="input input-text">
              <TextField
                id="standard-basic"
                label="Location"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </FormControl>
          </div>
        )}
        {project && (
          <div className="group">
            <Form.Group controlId="formFile" className="file-project">
              <Form.Control
                type="file"
                name="scheme"
                value={schemeValue}
                onChange={(e) => handleFileUpload(e)}
              />
              <div className="custom-input-file">
                <label
                  htmlFor="formFile"
                  className={schemeValue && "file-selected"}
                >
                  <span>Scheme/Project</span>
                  <span><img src={uploadImg} alt="" /></span>
                </label>
                <p>{schemeValue.split("\\")[2]}</p>
              </div>
            </Form.Group>
          </div>
        )}
        <div className="message">
          <FormControl
            className={
              messageErr !== ""
                ? "input input-text catch-err"
                : "input input-text"
            }
            margin="dense"
          >
            <TextField
              id="standard-basic"
              label="Message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            {messageErr !== "" && <div className="error">{messageErr}</div>}
          </FormControl>
        </div>
        <div className="submit-button">
          <button type="submit">Submit</button>
        </div>
      </form>
    </ContactFormWrapper>
  );
};

export default ContactForm;
