const theme = {
  yellow: "#ddb443",
  text_black: "#0a0000",
  text_blue: "#22344a",
  dark_gray: "#343e48",
  dark_gray2: "#404347",
  input_color: "#7D848C",
  footer_color: "#141f2d",
  cta_color: "#f0c438",
  error_color: "#EB5757",
  border_color: "#7D848C",
  button_yellow: "#F0C438",
  body_1: {
    "font-family": "Roboto",
    "font-size": "48px",
    "line-height": "56px",
    "font-style": "italic",
    "letter-spacing": "1.5px",
  },
  body_2: {
    "font-family": "Roboto",
    "font-size": "36px",
    "line-height": "45px",
    "letter-spacing": "4px",
    "font-weight": "300",
  },
  body_3: {
    "font-family": "Roboto",
    "font-size": "24px",
    "line-height": "39px",
    "font-style": "italic",
  },
  body_4: {
    "font-family": "Roboto",
    "font-size": "24px",
    "line-height": "39px",
    "font-weight": "300",
  },
  body_5: {
    "font-family": "Roboto",
    "font-size": "12px",
    "line-height": "19px",
    "font-weight": "300",
  },
  subtitle_1: {
    "font-family": "Roboto",
    "font-size": "24px",
    "line-height": "39px",
    "font-weight": "500",
  },
  subtitle_2: {
    "font-family": "Roboto",
    "font-size": "24px",
    "line-height": "39px",
    "font-weight": "300",
  },
  caption_1: {
    "font-family": "Roboto",
    "font-size": "24px",
    "line-height": "39px",
    "font-weight": "500",
  },
  caption_2: {
    "font-family": "Roboto",
    "font-size": "18px",
    "line-height": "29px",
    "font-weight": "500",
  },
  button_1: {
    "font-family": "Roboto",
    "font-size": "18px",
    "line-height": "21px",
    "font-style": "italic",
  },
  button_CTA1: {
    "font-family": "Roboto",
    "font-size": "18px",
    "line-height": "21px",
    "font-style": "italic",
  },
  button_CTA2: {
    "font-family": "Roboto",
    "font-size": "18px",
    "line-height": "21px",
    "font-weight": "500",
  },
  button_txt1: {
    "font-family": "Roboto",
    "font-size": "18px",
    "line-height": "21px",
    "font-weight": "700",
  },
  button_txt2: {
    "font-family": "Roboto",
    "font-size": "18px",
    "line-height": "21px",
  },
};

export default theme;
