import React from "react";
import styled from "styled-components";
import { Carousel } from "react-bootstrap";
import testimonialImg from "../Images/testimonial-image.png";
import "bootstrap/dist/css/bootstrap.min.css";

const TestimonialWrapper = styled.div`
  padding-top: 250px;
  padding-bottom: 150px;
  color: ${(props) => props.theme.text_blue};
  text-align: center;
  h2 {
    color: ${(props) => props.theme.text_blue};
  }
  .carousel-item {
    height: 350px;
  }
  .carousel-indicators [data-bs-target] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 76px;
    margin-left: 76px;
    background-color: #7d848c;
  }
  .carousel-indicators[data-bs-target]: (first-of-type) {
    margin-left: 0;
  }
  .carousel-indicators [data-bs-target]:last-of-type {
    margin-right: 0;
  }
  .carousel-indicators .active {
    background-color: ${(props) => props.theme.button_yellow};
  }
  .carousel-caption {
    color: ${(props) => props.theme.text_blue};
    position: unset;
    width: 80%;
    margin: 0 auto;
    p {
      ${(props) => props.theme.body_3};
      margin-bottom: 35px;
    }
    img {
    }
    span {
      ${(props) => props.theme.body_5};
      display: block;
      text-align: center;
    }
  }

  @media (max-width: 426px) {
    padding-top: 71px;
    padding-bottom: 71px;
    .carousel-indicators [data-bs-target] {
    }
    h2 {
      font-size: 36px !important;
      line-height: 45px;
    }
    .carousel-indicators {
      margin: 0 auto;
    }
    .carousel-indicators [data-bs-target] {
      margin-right: 15px;
      margin-left: 15px;
      width: 12px;
      height: 12px;
    }
    .carousel-indicators[data-bs-target]: (first-of-type) {
      margin-left: 0;
    }
    .carousel-indicators [data-bs-target]:last-of-type {
      margin-right: 0;
    }
    .carousel-caption {
      p {
        font-size: 18px !important;
        line-height: 29px;
      }
    }
  }
`;
const TestimonialsSlider = () => {
  return (
    <TestimonialWrapper className="bootstrap-scope">
      <h2>What our clients say</h2>
      <Carousel className="bootstrap-scope">
        <Carousel.Item interval={1000} className="bootstrap-scope">
          <Carousel.Caption className="bootstrap-scope">
            <p>
              VA Engineering provided precise and detailed steel calculations
              for our construction project in The Netherlands. We had excellent
              collaboration with Viktor and his team.
            </p>
            <img src={testimonialImg} alt="" />
            <span>John Peter </span>
            <span>GS Staalwerken</span>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000} className="bootstrap-scope">
          <Carousel.Caption className="bootstrap-scope">
            <p>
              VA Engineering provided precise and detailed steel calculations
              for our construction project in The Netherlands. We had excellent
              collaboration with Viktor and his team.
            </p>
            <img src={testimonialImg} alt="" />
            <span>John Peter </span>
            <span>GS Staalwerken</span>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000} className="bootstrap-scope">
          <Carousel.Caption className="bootstrap-scope">
            <p>
              VA Engineering provided precise and detailed steel calculations
              for our construction project in The Netherlands. We had excellent
              collaboration with Viktor and his team.
            </p>
            <img src={testimonialImg} alt="" />
            <span>John Peter </span>
            <span>GS Staalwerken</span>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000} className="bootstrap-scope">
          <Carousel.Caption className="bootstrap-scope">
            <p>
              VA Engineering provided precise and detailed steel calculations
              for our construction project in The Netherlands. We had excellent
              collaboration with Viktor and his team.
            </p>
            <img src={testimonialImg} alt="" />
            <span>John Peter </span>
            <span>GS Staalwerken</span>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </TestimonialWrapper>
  );
};

export default TestimonialsSlider;
