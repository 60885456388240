import React from "react";
import styled from "styled-components";
import arrowRight from "../Images/arrow-right.png";
import { Link } from "react-router-dom";
import phone from "../Images/floating-phone.png";
const MainContainerWrapper = styled.div`
  background-color: white;
  padding: 0 61px 181px;
  .container-wrapper {
    position: relative;
    z-index: 10;
    background-color: white;
    display: flex;
    justify-content: space-around;
    margin: -61px auto 0;
    height: 383px;
  }

  .containerTitle {
    color: ${(props) => props.theme.text_blue};
    margin-top: 86px;
    width: 40%;
    height: 312px;
  }
  .containerContent {
    ${(props) => props.theme.caption_1};
    color: ${(props) => props.theme.text_blue};
    margin-top: 86px;
    width: 40%;
    height: 234px;
    .floating-phone{
        display:none;
    }
  }
  .containerContent p {
    ${props=> props.theme.body_4}
    font-weight:400;
    margin-bottom: 30px;
  }

  @media (max-width: 426px) {
    padding: 50px 16px 70px;
    background-color:transparent;
    .container-wrapper {
      height: auto;
      z-index:0;
      margin: 0;
      flex-direction: column;
      .containerTitle {
        margin-top:0;
        width: auto;
        height: auto;
        h2 {
          font-size: 36px !important;
          line-height: 45px;
          font-weight:400;
        }
      }
      .containerContent{
          width: auto;
          height: auto;
          margin-top:25px;
          p{
              font-size: 18px;
              line-height:30px;
          }
          .floating-phone{
              display:block;
              position:absolute;
              bottom:0;
              right:0;
              img{
                  display:block;
                  width:100%;
              }
          }
      }
    }
  }
`;
const MainContainer = (props) => {
  return (
    <MainContainerWrapper>
      <div className="container-wrapper">
        <div className="containerTitle">
          <h2>
            VA Engineering is an innovative steel detailing provider with an
            international reach
          </h2>
        </div>
        <div className="containerContent">
          <p>
            We are commited to provide high quality engineering services for
            steel and concrete structures in commercial and industrial sectors.
            Our international expertise and detailing practices guarantee
            accurate, comprehensive and time-sensitive results.
          </p>
          <Link className="buttonReadMore" to="/about">
            More about us <img src={arrowRight} alt="Arrow" />
          </Link>
          <Link className="floating-phone">
          <img src={phone} alt="" />
          </Link>
        </div>
      </div>
    </MainContainerWrapper>
  );
};

export default MainContainer;
