import React from 'react';

import styled from "styled-components";
import ProjectFilterSector from './ProjectFilterSectors';
import ProjectFilterLocation from './ProjectFilterLocation';

const Wrapper = styled.div`
display:flex;
box-shadow: 0px 4px 44px rgba(140, 140, 140, 0.25);
margin-bottom:69px;
justify-content:center;
@media (max-width: 426px) {
    flex-direction: column;
    box-shadow: none;
    margin-bottom:30px;
}
`

const ProjectFilter = (props) => {
    return (
        <Wrapper>
        <ProjectFilterSector/>
        {/* <ProjectFilterLocation/> */}
        </Wrapper>
    );
};

export default ProjectFilter;