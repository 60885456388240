import React from "react";
import MainContainer from "./MainContainer";
import SliderContainer from "./SliderContainer";
import Mission from "./Mission";
import Intro from "./Intro";
import ProjectSlider from "./ProjectSlider";
import TestimonialsSlider from "./TestimonialsSlider";
import PrivacyContainer from "./PrivacyContainer";
import Partners from "./Partners";
import News from "./News";
import Navbar from "./Navbar";
import GetInTouch from "./GetInTouch";
import Footer from "./Footer";

const Home = (props) => {
  return (
    <>
      <Navbar />
      <Intro />
      <MainContainer />
      <SliderContainer />
      <Mission />
      <ProjectSlider />
      <TestimonialsSlider />
      <PrivacyContainer />
      <Partners />
      <News />
      <GetInTouch />
      <Footer/>
    </>
  );
};

export default Home;
