import React from 'react';
import styled from 'styled-components';
import Construction4 from '../Images/construction4.png';

const JointHeadingWrapper = styled.div`
display:flex;
align-items:center;
color:${props=>props.theme.text_blue};
.heading-image{
    padding-top:92px;
    margin-right:65px;
    margin-bottom:64px;
}
.title {
    ${props=>props.theme.body_2};
    font-family: 'Teko', sans-serif;
    margin-bottom:11px;
}
.content{
    ${props=>props.theme.caption_1};
    width:70%;
}
@media (max-width: 426px){
    flex-direction:column;
    .heading-image{
        margin-right:0;
    }
    .heading-image{
        img{
            display: block;
            width: 100%;
        }
    } 
    .heading-content{
        padding-left:16px;
        h3{
            font-size: 48px !important;
            line-height: 48px;
            letter-spacing: 4px;
        }
        .title{
            margin-bottom: 25px;
        }
        .content{
            font-weight:400;
            font-size: 18px !important;
            line-height: 29px;
        }
    }
}
`
const JointHeading = (props) => {
    return (
        <JointHeadingWrapper>
            <div className="heading-image">
                <img src={Construction4} alt="" />
            </div>
            <div className="heading-content">
                <h3>JOINT</h3>
                <p className='title'>Calculations</p>
                <p className="content">The calculation of joint connections in a steel structure is needed to develop steel structures detailing design.</p>
            </div>
        </JointHeadingWrapper>
    );
};

export default JointHeading;