import React from "react";

import styled from "styled-components";
import IntroImg from "../Images/construction2.png";
import arrow from "../Images/Arrow.png";

const IntroWrapper = styled.div`
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(40, 55, 73, 0.7) 100%,
      rgba(19, 29, 42, 0) 100%
    ),
    url(${IntroImg});
  height: 733px;
  transform: scaleX(-1);
  background-size: cover;
  position: relative;
  div {
    position: absolute;
    width: 580px;
    right: 150px;
    top: 50%;
    transform: scaleX(-1) translateY(-50%);
    color: white;
    img {
      margin-top: 70px;
      border-radius: 50%;
    }
    img:hover {
      box-shadow: 8px 10px 35px 0px #0e1825;
    }
  }

  @media (max-width: 426px) {
    div{
      right:16px;
      width:auto;
      h1{
        font-size:48px;
        line-height:56px;

      }
      img{
        position:absolute;
        margin-top:272px;
        z-index:10;
      }
    }
  }
`;
const Intro = () => {
  return (
    <IntroWrapper>
      <div>
        <h1>Creating Constructions for a lifetime</h1>
        <img src={arrow} alt="" />
      </div>
    </IntroWrapper>
  );
};

export default Intro;
