import React, { useContext } from "react";
import AboutHeading from "./AboutHeading";
import AboutSpecs from "./AboutSpecs";
import AboutTeam from "./AboutTeam";
import Ideas from "./Ideas";
import NavbarBlack from "./NavbarBlack";
import GetInTouch from "./GetInTouch";
import Footer from "./Footer";
import { GlobalContext } from "../Consts/GlobalContext";
const AboutUs = (props) => {
  let { isServicesOpen, isAboutUs } = useContext(GlobalContext);

  return (
    <div className={isServicesOpen || isAboutUs ? "hover-bg" : ""}>
      <NavbarBlack />
      <AboutHeading />
      <AboutTeam />
      <AboutSpecs />
      <Ideas />
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default AboutUs;
