

import React from 'react';

import styled from "styled-components";
import { FormControl, Select} from '@material-ui/core';

const ProjectFilterLocationWrapper =styled.div`
width:50%;
text-align:center;
padding: 32px 0;
.location{
    width:100%;
    span{
        color:${props=>props.theme.text_blue};
        ${props=>props.theme.subtitle_1};
        margin-right:22px;
    }
}
select{
    background-color:transparent;
    option{
        background-color:transparent;  
    }
}
select:focus{
    background-color:transparent;
}
@media (max-width: 426px) {
    width: 100%;
    text-align: center;
    padding: 25px 0;
    margin-bottom: 30px;
    box-shadow: none;
    box-shadow: 0px 4px 44px rgba(140, 140, 140, 0.25);
    .location{
        display:flex;
        flex-direction:column;
        span{
            width: 100%;
            margin-right: 0;
        }
        .input{
            display:block;
        }
    }
}
}
`
const ProjectFilterLocation = (props) => {
    return (
        <ProjectFilterLocationWrapper>
          <div className="location">
                <span>Filter by location</span>
                <FormControl className="input">
            <Select native defaultValue="" id="grouped-native-select">
              <option value={1}>All locations</option>
            </Select>
          </FormControl>
            </div>
        </ProjectFilterLocationWrapper>
    );
};

export default ProjectFilterLocation;