import React from "react";
import NavbarBlack from "./NavbarBlack";
import Footer from "./Footer";
import ContactForm from "./ContactForm";

const ContactPage = (props) => {
  return (
    <>
      <NavbarBlack />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ContactPage;
