import React, { createContext, useState } from "react";

const Context = createContext();

const Provider = ({ children }) => {
  const [project, setProject] = useState(true);
  const [filters, setFilters] = useState('All');

  const randomImage = (toNumber) => {
    let random = Math.floor(Math.random() * toNumber);
    return random;
  };
  const value = {
    project,
    setProject,
    randomImage,
    filters,
    setFilters,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const GlobalContext = Context;
export const GlobalProvider = Provider;
