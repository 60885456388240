import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Construction1 from "../Images/construction1.png";
import Construction2 from "../Images/construction2.png";
import Construction3 from "../Images/construction3.png";

const SliderContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 640px;
  left: 0px;
  top: 0px;
  a {
    width: 33.3%;
    text-decoration:none;
  }
  .slider {
    background-size: cover;
    background-repeat: no-repeat;
    height: 640px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .slider-container1 {
    background-image: linear-gradient(
        rgba(34, 52, 74, 0.7),
        rgba(34, 52, 74, 0.7)
      ),
      url(${Construction1});
    margin-right: 5px;
  }
  .slider-container2 {
    background-image: linear-gradient(
        rgba(34, 52, 74, 0.7),
        rgba(34, 52, 74, 0.7)
      ),
      url(${Construction2});
    margin-right: 5px;
  }
  .slider-container3 {
    background-image: linear-gradient(
        rgba(34, 52, 74, 0.7),
        rgba(34, 52, 74, 0.7)
      ),
      url(${Construction3});
  }
  .slider-container1:hover {
    background-image: linear-gradient(
        rgba(34, 52, 74, 0.9),
        rgba(34, 52, 74, 0.9)
      ),
      url(${Construction1});
  }
  .slider-container2:hover {
    background-image: linear-gradient(
        rgba(34, 52, 74, 0.9),
        rgba(34, 52, 74, 0.9)
      ),
      url(${Construction2});
  }
  .slider-container3:hover {
    background-image: linear-gradient(
        rgba(34, 52, 74, 0.9),
        rgba(34, 52, 74, 0.9)
      ),
      url(${Construction3});
  }
  .slider-content {
    h2 {
      width: 95%;
      color: white;
      text-align: center;
      span{
        ${props =>props.theme.body_2}
      }
    }
  }


  @media (max-width: 426px) {
    overflow-x:scroll;
    a{
      width:auto;
    }
    .slider {
      width: 300px;
    }
    .slider-content {
      h2{
        font-size:48px !important;
        line-height:56px;
        span{
          letter-spacing:1.5px;
        }
      }
    }
  }
`;
const SliderContainer = (props) => {
  return (
    <SliderContainerWrapper>
      <Link to="/joint-calculations">
        <div className="slider slider-container1">
          <div className="slider-content">
            <h2>JOINT <span>Calculations</span></h2>
          </div>
        </div>
      </Link>
      <Link>
        <div className="slider slider-container2">
          <div className="slider-content">
            <h2>STEEL <span>Detailing</span></h2>
          </div>
        </div>
      </Link>
      <Link>
        <div className="slider slider-container3">
          <div className="slider-content">
            <h2>STATIC <span>Calculations</span></h2>
          </div>
        </div>
      </Link>
    </SliderContainerWrapper>
  );
};

export default SliderContainer;
