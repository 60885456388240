import React from "react";
import arrowRight from "../Images/arrow-right.png";
const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};
const NewsCards = ({ id, title, description, url, createdAt }) => {
  return (
    <div>
      <img src={window.baseurl+'/' + url} alt="" />
      <p className="title">{title}</p>
      <span>
        <i className="far fa-calendar"></i>
        {new Date(createdAt).toLocaleDateString('en-EN',options)}
      </span>
      <p>{description}</p>
      <a href="/" className="buttonReadMore">
        Read more
        <img src={arrowRight} alt="Arrow" />
      </a>
    </div>
  );
};

export default NewsCards;
