import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import { FormControl, Select } from "@material-ui/core";
import { GlobalContext } from "../Consts/GlobalContext";

const ProjectFilterSectorWrapper = styled.div`
width:50%;
text-align:center;
padding: 32px 0;


.sector{
    width:100%;
    span{
        color:${(props) => props.theme.text_blue};
        ${(props) => props.theme.subtitle_1};
            margin-right:22px;
    }
}
select{
    background-color:transparent;
    option{
        background-color:transparent;  
    }
}
select:focus{
    background-color:transparent;
}
@media (max-width: 426px) {
    width: 100%;
    text-align: center;
    padding: 25px 0;
    margin-bottom: 30px;
    box-shadow: none;
    box-shadow: 0px 4px 44px rgba(140, 140, 140, 0.25);
    .sector{
        width:30%
        display:flex;
        flex-direction:column;
        span{
            width: 100%;
            margin-right: 0;
        }
        .input{
            display:block;
        }
    }
}

}
`;
const ProjectFilterSector = (props) => {
  const [services, setServices] = useState([]);
  const { setFilters } = useContext(GlobalContext);

  useEffect(() => {
    fetch(`${window.baseurl}/api/services`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
      });
  }, []);

  return (
    <ProjectFilterSectorWrapper>
      <div className="sector">
        <span>Filter by sector</span>
        <FormControl className="input">
          <Select
            native
            id="grouped-native-select"
            onChange={(e) => setFilters(e.target.value)}
          >
            <option value='All'>All sectors</option>
            {services.map((el) => (
              <option value={el.title}>{el.title}</option>
            ))}
          </Select>
        </FormControl>
      </div>
    </ProjectFilterSectorWrapper>
  );
};

export default ProjectFilterSector;
