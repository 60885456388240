import React from "react";
import styled from "styled-components";
import AboutUsImg from "../Images/about-us.png";
const AboutHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 171px;
  color:${props=>props.theme.text_blue};
  div {
    width: 50%;
    height: 100%;
    h3 {
      width: 75%;
      margin:0 120px 0 auto;
    }
  }
  img {
    width: 100%;
    display: block;
  }

  @media (max-width: 426px){
    flex-direction: column;
    div{
      width:auto;
      img{
        height:260px;
      }
      h3{
        width:auto;
        margin:0;
        font-size:36px !important;
        line-height:45px;
      }
    }
    div:last-of-type{
      margin-top:35px;
      padding-left:16px;
      padding-right:16px;
    }
  }
`;
const AboutHeading = () => {
  return (
    <AboutHeadingWrapper>
      <div>
        <img src={AboutUsImg} alt="" />
      </div>
      <div>
        <h3>
          We are driven by purpose and our mission and vision is to become the
          industry’s first and only choice.
        </h3>
      </div>
    </AboutHeadingWrapper>
  );
};

export default AboutHeading;
