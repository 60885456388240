import React from "react";
import styled from "styled-components";
import FooterLogo from "../Images/footer-logo.png";
import Facebook from "../Images/facebook.png";
import Twitter from "../Images/twitter.png";
import Youtube from "../Images/youtube.png";
import LinkedIn from "../Images/linkedin.png";
import { Link } from "react-router-dom";
import footerArrow from "../Images/footer-arrow.png";

const FooterWrapper = styled.div`
  background-color: ${(props) => props.theme.footer_color};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 53px;
  padding-left: 150px;
  padding-bottom: 50px;
  .title {
    margin-right: 179px;
    width: 25%;
  }
  .title p {
    ${(props) => props.theme.body_5};
    color: white;
    padding-top: 30px;
    width: 100%;
  }

  .aboutUs,
  .services,
  .follow {
    width: 20%;
  }
  .services p,
  .aboutUs p,
  .follow p {
    ${(props) => props.theme.button_CTA2};
    color: white;
    font-weight: 400;
  }
  li {
    ${(props) => props.theme.body_5};
    color: white;
    text-decoration: none;
    list-style-type: none;
    padding-bottom: 15px;
    font-weight: 400;
  }
  .bottom-left {
    display: flex;
  }
  .bottom-left p {
    ${(props) => props.theme.body_5};
    font-weight: 400;
    color: white;
    margin-right: 15px;
  }
  .follow {
    a {
      margin-right: 20px;
    }
  }
  .temp {
    margin-bottom: 32px;
  }
  a {
    text-decoration: none;
  }
  .footer-arrow {
    display: none;
  }

  @media (max-width: 426px) {
    padding-top: 35px;
    padding-left: 16px;
    flex-direction: column;
    .title {
      margin-bottom: 35px;
    }
    .footer-arrow {
      display: inline-block;
      margin-left: 5px;
      transition: all 0.6s ease-in-out;
    }
    .title,
    .services,
    .follow,
    .aboutUs,
    .bottom-left {
      width: 100%;
    }
    .services ul,
    .aboutUs ul {
      transition: all 1s;
      overflow: hidden;
      max-height: 0;
    }
    .services p,
    .aboutUs p:hover {
      cursor:pointer;
    }
    .bottom-left {
      margin-top: 35px;
    }
    .services ul.show,
    .aboutUs ul.show {
      max-height: 250px;
    }
    .rotate-arrow {
      transform: rotate(180deg);
    }
  }
`;
const Footer = (props) => {
  const handleClickShow = (e) => {
    e.stopPropagation();
    e.currentTarget.nextSibling.classList.toggle("show");
    e.currentTarget.children[0].classList.toggle("rotate-arrow");
  };
  return (
    <FooterWrapper>
      <div className="title">
        <img src={FooterLogo} alt="FooterLogo" />
        <p>
          We are commited to provide high quality engineering services for steel
          and concrete structures in commercial and industrial sectors. Our
          international expertise and detailing practices guarantee accurate,
          comprehensive and time-sensitive results.
        </p>
      </div>
      <div className="services">
        <p className="temp" onClick={(e) => handleClickShow(e)}>
          Services
          <img src={footerArrow} alt="" className="footer-arrow" />
        </p>
        <ul>
          <Link>
            <li>Steel Detailing</li>
          </Link>
          <Link to="/joint-calculations">
            <li>Joint Calculations</li>
          </Link>
          <Link>
            <li>Static Calculations</li>
          </Link>
          <Link>
            <li>Bim Modeling</li>
          </Link>
          <Link>
            <li>Rebar Detailing</li>
          </Link>
        </ul>
      </div>
      <div className="aboutUs">
        <p className="temp" onClick={(e) => handleClickShow(e)}>
          About us
          <img src={footerArrow} alt="" className="footer-arrow" />
        </p>
        <ul>
          <Link to="/about">
            <li>Our team</li>
          </Link>
          <Link>
            <li>Certificates</li>
          </Link>
          <Link>
            <li>News</li>
          </Link>
          <Link to="/contact">
            <li>Join our team</li>
          </Link>
        </ul>
      </div>
      <div className="follow">
        <p className="temp">Follow us</p>
        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
          <img src={Facebook} alt="Facebook Logo" />
        </a>
        <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">
          <img src={Twitter} alt="Twitter Logo" />
        </a>
        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
          <img src={Youtube} alt="Youtube Logo" />
        </a>
        <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
          <img src={LinkedIn} alt="LinkeIn Logo" />
        </a>
      </div>
      <div className="bottom-left">
        <p>Terms & Conditions</p>
        <p>Privacy policy</p>
        <p>Sitemap</p>
      </div>
    </FooterWrapper>
  );
};


export default Footer;
