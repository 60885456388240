import React from "react";
import styled from "styled-components";
import ArrowDown from "../Images/Arrow.png";
const IdeasWrapper = styled.div`
  padding: 180px 0 120px;
  text-align: center;
  color: ${(props) => props.theme.text_blue};
  h4 {
    width: 50%;
    margin: 18px auto 41px;
  }
  img{
    border-radius: 50%;
  }
  img:hover {
    box-shadow: 8px 10px 35px 0px #0e1825;
    cursor:pointer;
  }
  @media (max-width: 426px) {
    padding: 62px 0 60px;
    h2{
      text-align:center;
      font-size: 48px !important;
      line-height: 53px;
      font-weight: 400;
  }
  h4{
    font-weight:300;
    font-size:36px !important;
    line-height: 45px;
    margin: 20px auto;
    width: 85%;
  }
    }
  }
`;
const Ideas = () => {
  return (
    <IdeasWrapper>
      <h2>Let’s discuss your project</h2>
      <h4>
        We would love to share ideas, suggest options and answer your questions.
      </h4>
      <img src={ArrowDown} alt="" />
    </IdeasWrapper>
  );
};

export default Ideas;
