import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import arrow from "../Images/btn-arrow-right.png";
import { GlobalContext } from "../Consts/GlobalContext";

const GetInTouchWrapper = styled.div`
  background-color: ${(props) => props.theme.text_blue};
  display: flex;
  padding: 50px 150px 68px;
  .title {
    margin-right: 180px;
  }
  .title h4 {
    color: white;
  }
  .project-inquiry {
    margin-right: 150px;
  }
  .project-inquiry p,
  .general-inquiry p {
    ${(props) => props.theme.button_CTA2};
    color: white;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    margin-bottom: 38px;
    display: inline-block;
  }
  .project-inquiry button,
  .general-inquiry button {
    ${(props) => props.theme.subtitle_1};
    background-color: ${(props) => props.theme.yellow};
    color: ${(props) => props.theme.text_blue};
    display: flex;
    align-items: center;
    padding: 8px 20px;
    img {
      margin-left: 10px;
    }
  }
  a {
    text-decoration: none;
  }

  @media (max-width: 426px) {
    padding: 23px 16px 47px;
    flex-direction: column;
    .title {
      margin-bottom: 18px;

      h4 {
        font-size: 36px !important;
        line-height: 45px;
        font-weight: 400;
      }
    }
    .project-inquiry p,
    .general-inquiry p {
      margin-bottom: 30px;
    }
    .project-inquiry {
      margin-right: 0;
      margin-bottom: 40px;
    }
    .project-inquiry button,
    .general-inquiry button {
      padding: 5px 14px;
      font-size: 16px;
      line-height: 25px;
    }
  }
`;

const GetInTouch = () => {
  const { setProject } = useContext(GlobalContext);
  const handleProjectChange = (e) => {
    if (e.target.id === "project") {
      setProject(true);
    } else {
      setProject(false);
    }
  };
  return (
    <GetInTouchWrapper>
      <div className="title">
        <h4>Get in Touch</h4>
      </div>
      <div className="project-inquiry">
        <div>
          <p>+43 2638 1929</p>
        </div>
        <div>
          <p>myoffice@gmail.com</p>
        </div>
        <Link to="/contact">
          <button
            type="button"
            id="project"
            className="big-btn"
            onClick={(e) => handleProjectChange(e)}
          >
            Project Inquiry <img src={arrow} alt="Arrow" />
          </button>
        </Link>
      </div>
      <div className="general-inquiry">
        <div>
          <p>+43 2638 1929</p>
        </div>
        <div>
          <p>myoffice@gmail.com</p>
        </div>
        <Link to="/contact">
          <button
            type="button"
            id="general"
            className="big-btn"
            onClick={(e) => handleProjectChange(e)}
          >
            General Inquiry <img src={arrow} alt="Arrow" />
          </button>
        </Link>
      </div>
    </GetInTouchWrapper>
  );
};

export default GetInTouch;
