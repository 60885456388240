import React from "react";
import styled from "styled-components";
import AboutUsBg from "../Images/about-us-bg.png";

const AboutSpecsWrapper = styled.div`
  color:${props=>props.theme.text_blue};
  padding: 0px 150px 62px;
  background-image:url(${AboutUsBg});
  background-size: cover;
  background-position: right;
  div {
    margin-bottom: 43px;
    .caption {
      ${(props) => props.theme.caption_1};
    }
    .text {
      ${(props) => props.theme.subtitle_1};
      font-weight: 400;
    }
  }

  @media (max-width: 426px) {
    padding:0 16px 71px;
    div {
      .caption{
        font-size: 20px !important;
        line-height: 34px;
      }
      .text{
        font-size:18px !important;
        line-height: 29px;
      }
    }
  }
`;
const AboutSpecs = () => {
  return (
    <AboutSpecsWrapper>
      <div>
        <p className="caption">Network of specialists</p>
        <p className="text">
          We are commited to provide high quality engineering services for steel
          and concrete structures in commercial and industrial sectors. Our
          international expertise and detailing practices guarantee accurate,
          comprehensive and time-sensitive results.
        </p>
      </div>
      <div>
        <p className="caption">High quality services </p>
        <p className="text">
          We are commited to provide high quality engineering services for steel
          and concrete structures in commercial and industrial sectors. Our
          international expertise and detailing practices guarantee accurate,
          comprehensive and time-sensitive results.
        </p>
      </div>
      <div>
        <p className="caption">Quality control system</p>
        <p className="text">
          We are commited to provide high quality engineering services for steel
          and concrete structures in commercial and industrial sectors. Our
          international expertise and detailing practices guarantee accurate,
          comprehensive and time-sensitive results.
        </p>
      </div>
    </AboutSpecsWrapper>
  );
};

export default AboutSpecs;
