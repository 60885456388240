import React from 'react';
import styled from 'styled-components';

const JointSubWrapper = styled.div`
display:flex;
align-items:center;
color:${props=>props.theme.text_blue};
.sub-title{
  padding-left:150px;
  padding-right:234px;
  margin-bottom:150px;
}
.sub-content{
    padding-right:165px;
    margin-bottom:83px;
}
li{
    ${props=>props.theme.caption_1}
    margin-bottom:25px;

}
li::marker{
    color:${props=>props.theme.button_yellow};
}
@media (max-width: 426px){
    flex-direction:column;
    padding: 0 16px;
    .sub-title{
        padding:40px 0;
        margin-bottom:0;
        h3{
            font-weight:400;
            font-size: 36px !important;
            line-height: 45px;
        }
    }
    .sub-content{
        padding-right: 0;
        margin-bottom: 50px;   
        ul{
            padding-left:18px;
            li{
                font-weight:400;
                font-size: 18px !important;
                line-height: 29px;
                margin-bottom: 18px;
            }
            li:last-of-type{
                margin-bottom: 0;
            }
        } 
    }
}

`
const JointSub = (props) => {
    return (
        <JointSubWrapper>
            <div className="sub-title">
                <h3>Strong connections in construction and with people</h3>
            </div>
            <div className="sub-content">
                <ul>
                    <li>Joint calculations are necessary to select optimal connection configurations while using the minimum of material consumption.</li>
                    <li>In order to calculate joints in steel structures, the actual loads acting on each element are needed.</li>
                    <li>The result of joint calculation is to be presented in the form of a calculation note.</li>
                </ul>
            </div>
        </JointSubWrapper>
    );
};

export default JointSub;