import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import GetInTouch from "./GetInTouch";
import Ideas from "./Ideas";
import NavbarBlack from "./NavbarBlack";
import ProjectDetailContent from "./ProjectDetailContent";
import ProjectSlider from "./ProjectSlider";

const ProjectDetail = (props) => {
  const [details, setDetails] = useState([]);
  useEffect(() => {
    fetch(`${window.baseurl}/api/project/${props.match.params.id}`)
      .then((response) => response.json())
      .then((data) => {
        setDetails(data);
      });
  }, [props.match.params.id]);

  return (
    <>
      <NavbarBlack />
      <ProjectDetailContent location={details.location} description={details.description} name={details.title} detail_images={details.images}  />
      <ProjectSlider />
      <Ideas />
      <GetInTouch />
      <Footer />
    </>
  );
};

export default ProjectDetail;
