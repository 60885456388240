import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NewsCards from "./NewsCards";

const NewsWrapper = styled.div`
  color: ${(props) => props.theme.text_blue};
  padding: 0 75px 75px;
  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid ${(props) => props.theme.border_color};
  }
  h3 {
    margin-top: 75px;
    margin-bottom: 50px;
    text-align: center;
  }
  .news-cards {
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
    div {
      width: 33%;
      margin-right: 15px;
      margin-left: 15px;
      > img {
        width: 100%;
        display: block;
      }
      .title {
        ${(props) => props.theme.caption_1};
        color: ${(props) => props.theme.text_blue};
        font-weight: bold;
      }
      p {
        ${(props) => props.theme.subtitle_1};
        color: ${(props) => props.theme.text_blue};
        font-weight:400;
      }
      span {
        display: block;
        ${(props) => props.theme.button_txt2};
        color: ${(props) => props.theme.text_blue};
        margin: 17px 0;
        i {
          margin-right: 5px;
        }
      }
      a {
        display: flex;
        margin-top: 23px;
        color: ${(props) => props.theme.text_blue};
      }
    }
  }
  @media (max-width: 426px) {
    padding: 0 16px 65px;
    h3 {
      font-size: 36px !important;
      line-height: 45px;
      margin-top: 56px;
      margin-bottom: 30px;
    }
    .news-cards {
      margin-right: 0px;
      margin-left: 0px;
      flex-direction: column;
      div {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
        .title,
        p{
          font-size:18px;
          line-height:29px;
        }
        span{
          font-size:14px;
          line-height:12px;
        }
      }
      div:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }
`;

const News = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetch(`${window.baseurl}/api/news`)
      .then((response) => response.json())
      .then((data) => {
        setNews(data);
      });
  }, []);
  return (
    <NewsWrapper>
      <hr />
      <h3>News</h3>
      <div className="news-cards">
        {news.map((news) => (
          <NewsCards
            key={news.id}
            id={news.id}
            title={news.title}
            description={news.description}
            url={news.image}
            createdAt={news.created_at}
          />
        ))}
      </div>
    </NewsWrapper>
  );
};

export default News;
