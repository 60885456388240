import React from "react";
import styled from "styled-components";
import arrowRight from "../Images/arrow-right.png";

const MissionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
  padding-left: 157px;
  .missionTitle {
    color: ${(props) => props.theme.text_blue};
    width: 50%;
    margin-right: 165px;
  }
  .missionContent {
    ${(props) => props.theme.caption_1};
    color: ${(props) => props.theme.text_blue};
    width: 50%;
    margin-right: 117px;
    p {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 426px) {
    flex-direction: column;
    padding-bottom: 71px;
    padding-top: 71px;
    padding-left: 20px;
    .missionTitle{
        width:auto;
        margin-right:0;
        h2{
            font-size:36px !important;
            line-height:45px;
        }
    }
    .missionContent{
        width: auto;
        margin-right:0;
        margin-top:25px;
        p{
            font-size:18px;
            line-height:29px;
        }
    }
  }

`;
const Mission = (props) => {
  return (
    <MissionWrapper>
      <div className="missionTitle">
        <h2>
          Our mission and vision is to become the industry’s first and only
          choice
        </h2>
      </div>
      <div className="missionContent">
        <p>
          We are commited to provide high quality engineering services for steel
          and concrete structures in commercial and industrial sectors. Our
          international expertise and detailing practices guarantee accurate,
          comprehensive and time-sensitive results.
        </p>
        <a href="/" className="buttonReadMore">
          Our services
          <img src={arrowRight} alt="Arrow" />
        </a>
      </div>
    </MissionWrapper>
  );
};

export default Mission;
