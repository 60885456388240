import React from 'react';
import NavbarBlack from "./NavbarBlack";
import GetInTouch from "./GetInTouch";
import Footer from "./Footer";
import Ideas from './Ideas';
import ProjectSlider from './ProjectSlider';
import JointHeading from './JointHeading';
import JointSub from './JointSub';
import JointImages from './JointImages';

const JointCalculations = (props) => {
    return (
        <>
            <NavbarBlack />
            <JointHeading/>
            <JointSub/>
            <JointImages/>
            <ProjectSlider/>
            <Ideas/>
            <GetInTouch />
            <Footer />
        </>
    );
};

export default JointCalculations;