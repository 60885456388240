import React from 'react';
import styled from "styled-components";

const ProjectHeadingWrapper = styled.div`
div{
    padding-top:116px;
    padding-left:150px;
    padding-bottom:25px;
}
div h3{
        color:${props=>props.theme.text_blue};
        text-transform:uppercase;
}
@media (max-width: 426px) {
    div{
        padding-top:94px;
        padding-left:16px;
        padding-bottom:18px;
        h3{
            font-weight:500;
            font-size 48px!important;
            line-height:56px;
            letter-spacing:4px;
        }
    }
}

`
const ProjectHeading = (props) => {
    return (
        <ProjectHeadingWrapper>
                <div>
                <h3>Our Projects</h3>
                </div>
        </ProjectHeadingWrapper>
    );
};

export default ProjectHeading;