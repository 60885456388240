import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline; 
        box-sizing: border-box; 

    }
    body{
        vertical-align: baseline;
    }

    button{
        border:0;
        :focus, :visited, :active {
            outline: none;
            box-shadow: none;
        }
    }
    h1{
        font-family: 'Teko', sans-serif;
        font-size:80px;
        line-height:70px;
        letter-spacing:2px;
    }
    h2{
        font-size:65px !important;
        line-height:77px;
        letter-spacing:4px;
        font-family: 'Teko', sans-serif;
        font-weight:500;
    }
    h3{
        font-family: 'Teko', sans-serif;
        font-size:65px !important;
        line-height:77px;
    }
    h4{
        font-family: 'Teko', sans-serif;
        font-size:48px !important;
        line-height:56px;
        letter-spacing:1.5px;
        font-weight:500;
    }
    .buttonReadMore{
        color: ${(props) => props.theme.text_blue};
        ${(props) => props.theme.button_txt1};
        width: 150px;
        height: 21px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        text-decoration: none;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    a.get-in-touch {
        text-decoration:none;
        font-size: 18px;
        line-height: 21px;
        padding: 8px;
        background-color: ${(props) => props.theme.button_yellow};
        border-radius: 0;
        margin-right: 54px;
        color: ${(props) => props.theme.text_blue};
        ${(props) => props.theme.button_CTA2}
      }

      .big-btn:hover{
        box-shadow: inset 0px 0px 0px 2px #fff,8px 10px 35px 0px #0E1825;
      }
      .inner-navbar {
        display: flex;
        align-items: center;
      .menu {
        margin-right: 70px;
        list-style: none;
        display: flex;
        > li:not(:last-of-type) {
          margin-right: 70px;
        }
        li a {
            color: ${(props) => props.theme.text_blue};
            text-decoration: none;
        }
        .dropdown-menu.show .hover-bg{
          background-color:black;
        }
        .dropdown-menu {
            background-color: white;
            border:0;
            border-left: 8px solid ${(props) => props.theme.button_yellow};
            opacity:0;
            position: absolute;
            top: 40px !important;
            left: -30px !important;
            transform:none !important;
            width: 240px;
            list-style: none;
            padding: 45px 35px;
            cursor: default;
            pointer-events:none;
            border-radius: 0 !important;
            
            a.coming-soon{
              cursor:not-allowed;
            }
            a.coming-soon:hover{
              font-weight: 400;
            }
            a {
              ${(props) => props.theme.button_txt2};
              color: ${(props) => props.theme.text_blue};
              display: block;
              padding: 16px 0;
              text-decoration: none;
              pointer-events: auto;
            }
            a:not(:last-of-type)  {
              border-bottom: 1px solid ${(props) => props.theme.blue};
            }
            &::before {
              content: "";
              display: block;
              width: 40px;
              height: 40px;
              background-color: white;
              position: absolute;
              top: -5px;
              left: 20px;
              transform: rotate(45deg);
            }
          }
        li a{
          ${(props) => props.theme.button_txt2}
          color: white;
          .projects {
            ${(props) => props.theme.button_txt2}
            color: white;
            text-decoration: none;
          }
        }
        li:hover {
          cursor: pointer;
        }
        .hover,
        li:hover {
          color: ${(props) => props.theme.yellow};
        }
      }
    }
    .dropdown-toggle::after{
      display:none;
    }
    .inner-navbar ul li a {
        padding: 0;
      }
      .show{
        opacity:1 !important;
      }
      .dropdown-item:focus,
      .dropdown-item:hover{
        background:none;
      }

  @media (max-width: 426px){
    .inner-navbar {
      flex-direction: column;
    .menu {
      flex-direction: column;

    }
  }
      
`;

export default GlobalStyle;
