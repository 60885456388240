import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import arrowSlider from "../Images/arrow-slider.png";
import { Carousel } from "3d-react-carousal";
import ProjectSliderCards from "./ProjectSliderCards";
import { GlobalContext } from "../Consts/GlobalContext";

const ProjectSliderWrapper = styled.div`
  background-color: ${(props) => props.theme.text_blue};
  height: 580px;
  padding-top: 54px;
  padding-left: 150px;
  h1 {
    color: white;
    margin-bottom: 34px;
  }
  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.preactive
    .slider-single-content {
    display: none;
  }
  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.active
    .slider-left {
    display: none;
  }

  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single
    .slider-single-content {
    box-shadow: none;
  }

  .react-3d-carousel .slider-container .slider-right div {
    border: 0;
    margin-top: 0;
    background-image: url(${arrowSlider});
    height: 65px;
    width: 35px;
    background-size: cover;
  }
  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single
    .slider-single-content
    img {
    width: 767px;
    height: 515px;
  }
  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.proactive
    .slider-single-content {
    transform: translateX(80%) scale(0.8);
    opacity: 1;
  }
  .react-3d-carousel .slider-container .slider-right {
    left: 150%;
  }
  .react-3d-carousel .slider-container .slider-right div i {
    display: none;
  }
  .react-3d-carousel .slider-container {
    margin: -0px auto 0px -20px;
    left: 0;
  }
  .react-3d-carousel .slider-container .slider-content {
    transform: none;
    left: 0;
    top: 0;
  }
  .react-3d-carousel {
    overflow: hidden;
  }

  @media (max-width: 426px) {
    padding-top: 35px;
    padding-left: 20px;
    padding-bottom: 60px;
    height: auto;
    .react-3d-carousel {
      height: 327px !important;
    }
    .react-3d-carousel
      .slider-container
      .slider-content
      .slider-single
      .slider-single-content
      img {
      height: 327px;
    }
    .react-3d-carousel .slider-container .slider-right {
      left: 150%;
    }
    h1 {
      font-size: 48px !important;
      line-height: 56px;
    }
  }
`;
const ProjectSlider = () => {
  const [sliderimg, setSliderimg] = useState([]);
  const {randomImage} = useContext(GlobalContext);
  useEffect(() => {
    fetch(`${window.baseurl}/api/projects`)
      .then((response) => response.json())
      .then((data) => {
        setSliderimg(data);
      });
  }, []);


  let slides = sliderimg.map((el) => (
    <ProjectSliderCards
      key={el.id}
      id={el.id}
      title={el.title}
      location={el.location}
      image={el.images[randomImage(el.images.length)]}
    />
  ));
  return (
    <ProjectSliderWrapper>
      <h1>Our projects</h1>
      <Carousel slides={slides} interval={1000} />
    </ProjectSliderWrapper>
  );
};

export default ProjectSlider;
