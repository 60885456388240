import React from "react";
import styled from "styled-components";
import TopRight from "../Images/top-right.png";
import arrowRight from "../Images/arrow-right.png";

const PrivacyContainerWrapper = styled.div`
  position: relative;
  padding-top: 150px;
  .top-right-image {
    background: url(${TopRight});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    width: 55%;
    height: 541px;
    right: 0;
    top: 0;
  }
  .bottom-left {
    background-color: ${(props) => props.theme.text_blue};
    position: relative;
    width: 55%;
    height: 541px;
    left: 0;
    top: 86px;
    padding-top: 75px;
    padding-right: 350px;
    padding-left: 150px;
  }
  .privacyTtile h1 {
    color: white;
    margin-bottom: 70px;
  }
  .buttonReadMore {
    ${(props) => props.theme.subtitle_1};
    width: auto;
    color: white;
    margin-top: 25px;
  }

  @media (max-width: 426px) {
    background-color: ${(props) => props.theme.text_blue};
    padding-top: 30px;
    padding-left: 16px;
    padding-right:150px;
    .privacyTtile h1 {
      font-size: 48px !important;
      line-height: 56px;
      margin-bottom: 20px;
    }
    .top-right-image {
      width: 270px;
      height: 203px;
      bottom: -41px;
      top: unset;
    }
    .bottom-left {
      padding-right: 0;
      padding-left: 0;
      width: auto;
      height: 480px;
      top: unset;
      padding-top: 0;
    }
    .buttonReadMore:not(:first-of-type) {
      margin-top: 40px;
    }
    .buttonReadMore{
      font-size:18px;
      line-height:29px;
    }
  }
`;

const PrivacyContainer = (props) => {
  return (
    <PrivacyContainerWrapper>
      <div className="bottom-left">
        <div className="privacyTtile">
          <h1>The steel you can trust</h1>
        </div>
        <div className="privacyContainer">
          <a href="/" className="buttonReadMore">
            Network of specialists
            <img src={arrowRight} alt="Arrow" />
          </a>
          <a href="/" className="buttonReadMore">
            High quality services
            <img src={arrowRight} alt="Arrow" />
          </a>
          <a href="/" className="buttonReadMore">
            Quality control system
            <img src={arrowRight} alt="Arrow" />
          </a>
        </div>
      </div>
      <div className="top-right-image"></div>
    </PrivacyContainerWrapper>
  );
};

export default PrivacyContainer;
