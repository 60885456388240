import React from 'react';

import styled from "styled-components";
import Footer from './Footer';
import GetInTouch from './GetInTouch';
import Ideas from './Ideas';
import NavbarBlack from './NavbarBlack';
import ProjectCards from './ProjectCards';
import ProjectFilter from './ProjectFilter';
import ProjectHeading from './ProjectHeading';

const ProjectWrapper = styled.div`

`
const Projects = (props) => {
    return (
        <ProjectWrapper>
            <NavbarBlack/>
            <ProjectHeading/>
            <ProjectFilter/>
            <ProjectCards/>
            <Ideas/>
            <GetInTouch/>
            <Footer/>
        </ProjectWrapper>
    );
};

export default Projects;