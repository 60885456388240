import React from "react";
import { ThemeProvider } from "styled-components";
import "./App.css";

import GlobalStyle from "./Consts/GlobalStyle";
import theme from "./Consts/Theme";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";
import JointCalculations from "./Components/JointCalculations";
import ContactPage from "./Components/ContactPage";
import Projects from "./Components/Projects";
import ProjectDetail from "./Components/ProjectDetail";
import ScrollToTop from "./Components/ScrollToTop";
import { GlobalProvider } from "./Consts/GlobalContext";
function App() {
  return (
    <>
      <GlobalProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <div className='App'>
              <ScrollToTop>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/about" component={AboutUs} />
                  <Route
                    path="/joint-calculations"
                    component={JointCalculations}
                  />
                  <Route path="/projects" exact component={Projects} />
                  <Route
                    path="/projects/details/:id"
                    component={ProjectDetail}
                  />
                  <Route path="/contact" component={ContactPage} />
                </Switch>
              </ScrollToTop>
            </div>
          </ThemeProvider>
        </Router>
      </GlobalProvider>
    </>
  );
}

export default App;
