import React from "react";
import styled from "styled-components";

const ProjectDetailContentWrapper = styled.div`
  padding: 170px 150px 120px;
  .left {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-basis: 75%;
    img {
      width: 100%;
      display: block;
      height: 33%;
    }
    img:not(:last-of-type) {
      width: 25%;
    }
    img:last-of-type {
      width: 75%;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }
  }
  .content {
    width: 50%;
    margin: 50px 0;
    color: ${(props) => props.theme.text_blue};
    h4 {
      font-weight: 300;
    }
    p {
      ${(props) => props.theme.body_4};
      font-weight: 400;
    }
  }
  .long-desc {
    ${(props) => props.theme.body_4};
    color: ${(props) => props.theme.text_blue};
    font-weight: 400;
  }
  .bottom-mobile {
    display: none;
  }
  .left-mobile {
    display: none;
  }

  @media (max-width: 426px) {
    padding: 90px 0 65px;

    .left-mobile {
      display: block;
      img {
        width: 100%;
        display: block;
      }
    }
    .content {
      margin: 25px 0;
      padding: 0px 16px;
      width: auto;
      h2 {
        font-size: 48px !important;
        line-height: 56px;
      }
      h4 {
        font-size: 36px !important;
        line-height: 45px;
      }
      p {
        font-size: 18px !important;
        line-height: 29px;
      }
    }
    .long-desc {
      padding: 0px 16px;
      font-size: 18px !important;
      line-height: 29px;
    }
    .left {
      display: none;
    }
    .bottom-mobile {
      display: block;
      padding: 0 16px;
      > div {
        display: flex;
        overflow-x: scroll;
        margin-right: -10px;
        margin-left: -10px;
        > div {
          margin-right: 10px;
          margin-left: 10px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
  }
`;

const ProjectDetailContent = ({
  name,
  location,
  description,
  detail_images,
}) => {
  return (
    <ProjectDetailContentWrapper>
      <div className="left-mobile">
        {detail_images && (
          <img src={window.baseurl + "/" + detail_images[0].images} alt="" />
        )}
      </div>
      <div className="left">
        {detail_images &&
          detail_images.map((el) => (
            <img src={window.baseurl + "/" + el.images} alt="" />
          ))}
      </div>
      <div className="content">
        <h2>{name}</h2>
        <h4>{location}</h4>
        <p>{description}</p>
      </div>
      <div className="long-desc">{description}</div>
      <div className="bottom-mobile">
        <div>
          <div>
            {/* {detail_images && detail_images.length > 1
              ? console.log("")
              : console.log("")} ako ima pomalce od 4 sliki */}
            {detail_images && (
              <img
                src={window.baseurl + "/" + detail_images[1].images}
                alt=""
              />
            )}
          </div>
          <div>
            {detail_images && (
              <img
                src={window.baseurl + "/" + detail_images[2].images}
                alt=""
              />
            )}
          </div>
          <div>
            {detail_images && (
              <img
                src={window.baseurl + "/" + detail_images[3].images}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </ProjectDetailContentWrapper>
  );
};

export default ProjectDetailContent;
