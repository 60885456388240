import React from "react";
import {NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../Images/logo-black.png";
import footerArrow from "../Images/vector-blue.svg";

const NavbarBlackWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  .menu-mobile {
    display: none;
  }
  height: 79px;
  padding: 18.5px 150px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  .logo img {
    width: 100px;
    height: 42px;
    display: block;
  }
  .inner-navbar .menu li a {
    color: ${(props) => props.theme.text_blue};
  }
  .inner-navbar .menu li a:hover {
    font-weight: bold;
  }
  .nav-item.show {
    font-weight: bold;
  }
  .get-in-touch:hover {
    box-shadow: 8px 10px 35px #dadde0;
  }
  .dropdown-menu {
    box-shadow: 8px 10px 35px #dadde0;
  }

  @media (max-width: 426px) {
    height: auto;
    padding: 12px 16px;
    .logo img {
      width: 71px;
      height: 30px;
    }
    .inner-navbar {
      display: none;
    }
    .menu-mobile{
      display:block;
    }
    .open-mobile {
      width: 26px;
      display: flex;
      flex-direction: column;
      span {
        border: 1px solid ${(props) => props.theme.text_blue};
        border-radius: 10px;
      }
      span:not(:last-child) {
        margin-bottom: 6px;
      }
    }
    .mobile-menu-links {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    .menu-mobile-opened {
      padding: 12px 16px 46px;
      position: fixed;
      height: 100vh;
      width: 100%;
      left: 101%;
      top: 0;
      background-color: white;
      z-index: 1000;
      transition: all 1s;
      .footer-arrow {
        display: inline-block;
        margin-left: 5px;
        transition: all 0.6s ease-in-out;
      }

      .mobile-logo {
        display: flex;
        justify-content: space-between;
        img {
          width: 71px;
          height: 30px;
        }
      }
      .services-mobile {
        margin: 50px 0 30px;
      }
      .services-mobile p,
      .about-us-mobile p {
        ${(props) => props.theme.button_CTA2};
        color: ${(props) => props.theme.text_blue};
      }
      .services-mobile p.active,
      .about-us-mobile p.active {
        transition: all 1s ease-in-out;
        display: inline-block;
        border-bottom: 1px solid ${(props) => props.theme.button_yellow};
      }
      .services-mobile ul,
      .about-us-mobile ul {
        margin-top: 25px;
        padding-left: 26px;
        list-style-type: none;
        transition: all 1s ease-in-out;
        overflow: hidden;
        max-height: 0;
        li a {
          display: block;
          max-width: 160px;
          text-decoration: none;
          ${(props) => props.theme.button_txt2};
          color: ${(props) => props.theme.text_blue};
          padding-bottom: 9px;
          border-bottom: 1px solid #b9b9b9;
        }
        li:not(:last-child) {
          margin-bottom: 16px;
        }
      }
      .services-mobile ul.show,
      .about-us-mobile ul.show {
        max-height: 300px;
      }
    }
    .burger-menu-x {
      width: 26px;
      display: flex;
      justify-content: space-around;
      position: relative;
      flex-direction: column;
      span {
        width: 26px;
        border: 1px solid ${(props) => props.theme.text_blue};
        position: absolute;
      }

      span:first-child {
        transform: rotate(45deg);
      }
      span:last-child {
        transform: rotate(-45deg);
      }
    }
    .rotate-arrow {
      transform: rotate(180deg);
    }
    .open {
      left: 0;
    }
    .mobile-btn {
      display: block;
      text-align: center;
      background-color: ${(props) => props.theme.button_yellow};
      text-decoration: none;
      color: ${(props) => props.theme.text_blue};
      ${(props) => props.theme.button_CTA2}
      width:100%;
      padding: 12px 123px;
    }
    .projects-mobile {
      display: block;
      max-width: 160px;
      text-decoration: none;
      ${(props) => props.theme.button_CTA2};
      color: ${(props) => props.theme.text_blue};
      margin-bottom: 50px;
    }
  }
`;

const NavbarBlack = () => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.toggle("active");
    e.currentTarget.nextSibling.classList.toggle("show");
    e.currentTarget.children[0].classList.toggle("rotate-arrow");
  };

  const handleClickMenuOpen = (e) => {
    if (e.currentTarget.classList.contains("open-mobile")) {
      e.currentTarget.nextSibling.classList.add("open");
    } else {
      e.currentTarget.parentElement.parentElement.classList.remove("open");
    }
  };
  return (
    <NavbarBlackWrapper className="hover-bg">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="inner-navbar">
        <ul className="menu">
          <li>
            <NavDropdown title="Services" id="collasible-nav-dropdown">
              <NavDropdown.Item className="coming-soon" href="/">
                Steel Detailing
              </NavDropdown.Item>
              <NavDropdown.Item href="/joint-calculations">
                Joint Calculations
              </NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                Static Calculations
              </NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                BIM Modeling
              </NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                Rebar Detailing
              </NavDropdown.Item>
            </NavDropdown>
          </li>
          <li>
            <Link to="/projects" className="projects">
              Projects
            </Link>
          </li>
          <li>
            <NavDropdown title="About us" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/about">Our team</NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                Certificates
              </NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                News
              </NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                Contact
              </NavDropdown.Item>
              <NavDropdown.Item className="coming-soon" href="/">
                Join our team
              </NavDropdown.Item>
            </NavDropdown>
          </li>
        </ul>
        <Link to="/contact" className="get-in-touch">
          Get in touch
        </Link>
      </div>
      <div className="menu-mobile">
        <div className="open-mobile" onClick={(e) => handleClickMenuOpen(e)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="menu-mobile-opened">
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
            <div
              className="burger-menu-x"
              onClick={(e) => handleClickMenuOpen(e)}
            >
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="mobile-menu-links">
            <div>
              <div className="services-mobile">
                <p onClick={(e) => handleClick(e)}>
                  Services
                  <img src={footerArrow} alt="" className="footer-arrow" />
                </p>
                <ul>
                  <li>
                    <Link>Steel Detailing</Link>
                  </li>
                  <li>
                    <Link to="/joint-calculations">Joint Calculations</Link>
                  </li>
                  <li>
                    <Link>Static Calculations</Link>
                  </li>
                  <li>
                    <Link>BIM Modeling</Link>
                  </li>
                  <li>
                    <Link>Rebar Detailing</Link>
                  </li>
                </ul>
              </div>
              <Link to="/projects" className="projects-mobile">
                Projects
              </Link>
              <div className="about-us-mobile">
                <p onClick={(e) => handleClick(e)}>
                  About us
                  <img src={footerArrow} alt="" className="footer-arrow" />
                </p>
                <ul>
                  <li>
                    <Link to="/about">Our team</Link>
                  </li>
                  <li>
                    <Link>Certificates</Link>
                  </li>
                  <li>
                    <Link>News</Link>
                  </li>
                  <li>
                    <Link>Contact</Link>
                  </li>
                  <li>
                    <Link>Join our team</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <Link to="/contact" className="mobile-btn">
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </NavbarBlackWrapper>
  );
};

export default NavbarBlack;
